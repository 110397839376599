import React, { useEffect, useRef, useState } from "react";

import CiiZFactor from "./charts/CiiZFactor";
import { refreshCii, regulatoryContext } from "../api/data";
import ImoIntensityChart from "./charts/ImoIntensityChart";
import SelectBox from "./SelectBox";
import ErrorOverlaymodal from "../components/ErrorOverlayModal";
import useNumericInput from "../hooks/useNumericInput";
import { toast } from "react-toastify";


const ImoTabContent = ({
  zFactorValue,
  setZFactorValue,
  imoLevyAmount,
  setImoLevyAmount,
  imoPenalty,
  setImoPenalty,
  startYear,
  setStartYear,
  showYearError,
  setShowYearError,
  years,
  setError,
  error,
  errorPenalty,
  setErrorPenalty,
  errorLevyAmount,
  setErrorLevyAmount,
}) => {
  const [ciiChartData, setCiiChartData] = useState([]);
  const [intensityChartData, setIntensityChartData] = useState([]);
  const [isTouchedImo, setIsTouchedImo] = useState(false);
  const [open, setOpen] = useState(false);
  const [isTouchedLevy, setIsTouchedLevy] = useState(false);
  const [isTouchedZfactor, setIsTouchedZfactor] = useState(false);
  const [viewZfactorValue, setViewZfactorValue] = useState(null);
  const [zFactorError, setZFactorError] = useState('');

  const penaltyInputRef = useRef(null);
  const levyInputRef = useRef(null);
  const zFactorRef = useRef(null);
  const { handleOnKeyDown} = useNumericInput();

  const handleClose = () => {
    setOpen(false);
    setError(null);
  };

  const removeDecimalPart = (value) => {
    value = value.replace(/[-+]+$/, '');
    if (value.includes('.')) {
      const [integerPart, decimalPart] = value.split('.');
      if (decimalPart.length > 1) {
        value = `${integerPart}.${decimalPart.slice(0, 1)}`;
      }
    }
    return value;
  }

  const handleChangeZfactor = (event) => { 
    let value = event.target.value.trim();
    // Remove any characters except digits and a single period.
    value = value.replace(/[^0-9.]/g, '');
    
    // If the input is empty, reset states and show error.
    if (!value) {
        setZFactorError("Z-factor must be between 0% and 10%");
        setZFactorValue("");
        setViewZfactorValue("");
        return;
    }
    
    // Parse the value into a number.
    let parsedValue = parseFloat(value);
    
    // Validate the parsed value.
    if (!isNaN(parsedValue) && parsedValue >= 0 && parsedValue <= 10) {
        // Format the value to one decimal place for display.
        let formattedValue = parsedValue.toFixed(1);
        
        // Set the states with correctly formatted values.
        setZFactorValue((parsedValue / 100).toFixed(4)); // Store as a percentage with 4 decimal places.
        setViewZfactorValue(formattedValue); // Display with 1 decimal place.
        setZFactorError(""); // Clear any previous error.
        
        // Trigger the refresh function with a formatted value.
        getRefreshCii((parsedValue / 100).toFixed(4));
    } else {
        // Reset states and show an error message for invalid input.
        setZFactorError("Z-factor must be between 0% and 10%");
        setZFactorValue("");
        setViewZfactorValue("");
    }
  };

  const handleChangeLevyAmount = (event) => {
    let value = event.target.value.trim();
    value = removeDecimalPart(value);
    handleInputValueForLevyAmount(value)
  };

  const handleChangeImoPenalty = (event) => {
    let value = event.target.value.trim();
    value = removeDecimalPart(value);
    handleInputValueForImoPenalty(value)
  };


  const handleInputValueForLevyAmount = (value) => {
    const parsedValue = parseFloat(value);
    if (
      isNaN(parsedValue) ||
      parsedValue < 0 ||
      parsedValue > 500 ||
      value.startsWith("-")
    ) {
       value = value.slice(0, 3);
      setErrorLevyAmount("Please enter a levy amount between 0 and 500");
      setImoLevyAmount(value);
    } else {
      setImoLevyAmount(value);
      setErrorLevyAmount("");
    }
  };

  const handleInputValueForImoPenalty = (value) => {
    const parsedValue = parseFloat(value);
    if (
      isNaN(parsedValue) ||
      parsedValue < 0 ||
      parsedValue > 5000 ||
      value.startsWith("-")
    ) {
      value = value.slice(0, 4);
      setErrorPenalty("Please enter penalty between 0 to 5000");
      setImoPenalty(value);
    } else {
      setImoPenalty(value);
      setErrorPenalty("");
    }
  };

  const handleFocus = () => {
    setIsTouchedImo(true);
  };

  const handleFocusLevy = () => {
    setIsTouchedLevy(true);
  };

  const handleFocusZfactor = () => {
    setIsTouchedZfactor(true);
  }

  const getRefreshCii = (cii) => {
    if (cii) {
    refreshCii(cii)
      .then((res) => {
        setCiiChartData(res.data);
      })
      .catch((err) => {
        setError(err.message);
        setOpen(true);
        console.error(err);
      });
    }
  };

  handleInputValueForImoPenalty(imoPenalty)
  handleInputValueForLevyAmount(imoLevyAmount)


  const getRegulatoryContext = () => {
    regulatoryContext()
      .then((res) => {
        setIntensityChartData(res.data);
      })
      .catch((err) => {
        setError(err);
        setOpen(true);
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (zFactorValue !== null && zFactorValue >= 0 && zFactorValue <= 0.1) {
      getRefreshCii(zFactorValue);
      setViewZfactorValue(zFactorValue * 100);
      getRegulatoryContext();
      if (!isNaN(zFactorValue) && zFactorValue >= 0 && zFactorValue <= 0.1) {
        setError("");
      }
    } else {
      toast.error("Please enter valid z-factor value between 0 to 10", {
      position: "top-right",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      toastId: "z-factor",
    });
    }
  }, [zFactorValue]);

  const handleClickOutside = (event) => {
    if (penaltyInputRef.current && !penaltyInputRef.current.contains(event.target)) {
      setIsTouchedImo(false);
    }
    if (levyInputRef.current && !levyInputRef.current.contains(event.target)) {
      setIsTouchedLevy(false);
    }
    if (zFactorRef.current && !zFactorRef.current.contains(event.target)) {
      setIsTouchedZfactor(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div className="imo-container">
      <div className="carbon-container rounded-3 p-4">
        <div className="title">
          <p className="mb-0 text-dark">Carbon Levy</p>
        </div>
        <div className="carbon-input-container mt-3">
          <div className="levy-amount-container" ref={levyInputRef}>
            <p className="input-label fs-14">Levy Amount</p>
            <div className="common-input-v2">
              <span className="inside-text">USD/mtC02-eq</span>
              <input
                type="text"
                name="eufmNonComliance"
                placeholder="Enter Base Price"
                value={imoLevyAmount || ""}
                onFocus={handleFocusLevy}
                onChange={(e) => handleChangeLevyAmount(e)}
                className="pe-5 no-highlight"
                onKeyDown={(e) => {handleOnKeyDown(e)}}
              />
            </div>
          </div>
          <div className="start-year-container">
            <div className="input-container-no-flex">
              <p className="input-label fs-14">Start Year</p>
              <SelectBox
                show={true}
                selectedItem={startYear}
                listItems={years}
                setSelectedItem={(item) => setStartYear(item)}
                disabledOptions={[startYear]}
                showError={showYearError}
                setShowError={setShowYearError}
                className="small-select-box"
              />
            </div>
          </div>
        </div>
        {isTouchedLevy && errorLevyAmount && <p className="error mb-0">{errorLevyAmount}</p>}
      </div>
      <div className="imo-chart-container mt-4">
        <div className="cii-chart p-0 pt-4 gap-4 graph-containter border-1 rounded-3 bg-white overflow-hidden">
          <div className="d-flex justify-content-between px-4 align-items-center h-60">
            <div className="title">
              <p className="mb-0 text-dark">CII</p>
            </div>
            <div className="z-factor-input" ref={zFactorRef}>
              <div className="d-flex align-items-center justify-content-end">
                <p className="input-label fs-14 mb-0 me-2">Z-factor post 2027</p>
                <div className="mail-input pos-relative parameter common-input-v2">
                  <span className="inside-text">%</span>
                  <input
                    type="number"
                    name="eufmNonComliance"
                    placeholder="Enter Z-Factor"
                    className="bg-white"
                    value={viewZfactorValue || ''} 
                    onChange={(event) => handleChangeZfactor(event)}
                    onFocus={handleFocusZfactor}
                    onKeyDown={(e) => {handleOnKeyDown(e)}}
                  />
                </div>
              </div>
              {isTouchedZfactor && zFactorError && <p className="error mb-0">{zFactorError}</p>}
            </div>
          </div>
          <div className="cii-zfactor-chart mt-3">
            <div className="chart-bg">
              <CiiZFactor chartData={ciiChartData} />
            </div>
          </div>
        </div>
        <div className="intensity-limit p-0 pt-4 graph-containter border-1 rounded-3 bg-white overflow-hidden">
        <div className="d-flex justify-content-between px-4 align-items-center h-60">
          <div className="title">
            <p className="mb-0 text-dark">Intensity Limit</p>
          </div> 
          <div className="penlty-input" ref={penaltyInputRef}>
            <div className="d-flex align-items-center justify-content-end">
            <p className="input-label fs-14 mb-0 me-2 text-end"  style={{ whiteSpace: 'swrap' }}>Penalty non-compliance</p>
            <div className="mail-input pos-relative parameter common-input-v2">
              <span className="inside-text">EUR/GJ</span>
              <input
                type="text"
                name="eufmNonComliance"
                placeholder="Enter Penalty"
                className="bg-white"
                value={imoPenalty || ''}
                onFocus={handleFocus}
                onChange={(e) => handleChangeImoPenalty(e)}
                onKeyDown={(e) => {handleOnKeyDown(e)}}
              />
            </div>
            </div>
            {isTouchedImo && errorPenalty && <p className="error text-end mb-0">{errorPenalty}</p>}
          </div>
          </div>
          <div className="cii-zfactor-chart mt-3">
            <div className="chart-bg">
              <ImoIntensityChart chartData={intensityChartData} />
            </div>
          </div>
        </div>
      </div>
      <ErrorOverlaymodal
        show={open}
        handleClose={handleClose}
        errorMessage={
          error
            ? error.response
              ? error.response.data.message
              : "Unknown error occurred"
            : "No error"
        }
      />
    </div>
  );
};

export default ImoTabContent;
